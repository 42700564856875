@font-face {
  font-family: 'NationalWeb-Bold';
  src: url('../../fonts/NationalWeb-Bold.eot');
  src: url('../../fonts/NationalWeb-Bold.eot#iefix') format('embedded-opentype'),
    url('../../fonts/NationalWeb-Bold.woff2') format('woff2'),
    url('../../fonts/NationalWeb-Bold.woff') format('woff'),
    url('../../fonts/NationalWeb-Bold.svg#NationalWeb-Bold') format('svg');
  font-display: block;
}

@font-face {
  font-family: 'NationalWeb-BoldItalic';
  src: url('../../fonts/NationalWeb-BoldItalic.eot');
  src: url('../../fonts/NationalWeb-BoldItalic.eot#iefix') format('embedded-opentype'),
    url('../../fonts/NationalWeb-BoldItalic.woff2') format('woff2'),
    url('../../fonts/NationalWeb-BoldItalic.woff') format('woff'),
    url('../../fonts/NationalWeb-BoldItalic.svg#NationalWeb-BoldItalic') format('svg');
  font-display: block;
}

@font-face {
  font-family: 'NationalWeb-Book';
  src: url('../../fonts/NationalWeb-Book.eot');
  src: url('../../fonts/NationalWeb-Book.eot#iefix') format('embedded-opentype'),
    url('../../fonts/NationalWeb-Book.woff2') format('woff2'),
    url('../../fonts/NationalWeb-Book.woff') format('woff'),
    url('../../fonts/NationalWeb-Book.svg#NationalWeb-Book') format('svg');
  font-display: block;
}

@font-face {
  font-family: 'NationalWeb-Italic';
  src: url('../../fonts/NationalWeb-Italic.eot');
  src: url('../../fonts/NationalWeb-Italic.eot#iefix') format('embedded-opentype'),
    url('../../fonts/NationalWeb-Italic.woff2') format('woff2'),
    url('../../fonts/NationalWeb-Italic.woff') format('woff'),
    url('../../fonts/NationalWeb-Italic.svg#NationalWeb-Italic') format('svg');
  font-display: block;
}

@font-face {
  font-family: 'NationalWeb-Light';
  src: url('../../fonts/NationalWeb-Light.eot');
  src: url('../../fonts/NationalWeb-Light.eot#iefix') format('embedded-opentype'),
    url('../../fonts/NationalWeb-Light.woff2') format('woff2'),
    url('../../fonts/NationalWeb-Light.woff') format('woff'),
    url('../../fonts/NationalWeb-Light.svg#NationalWeb-Light') format('svg');
  font-display: block;
}

@font-face {
  font-family: 'NationalWeb-LightItalic';
  src: url('../../fonts/NationalWeb-LightItalic.eot');
  src: url('../../fonts/NationalWeb-LightItalic.eot#iefix') format('embedded-opentype'),
    url('../../fonts/NationalWeb-LightItalic.woff2') format('woff2'),
    url('../../fonts/NationalWeb-LightItalic.woff') format('woff'),
    url('../../fonts/NationalWeb-LightItalic.svg#NationalWeb-LightItalic') format('svg');
  font-display: block;
}

@font-face {
  font-family: 'NationalWeb-Medium';
  src: url('../../fonts/NationalWeb-Medium.eot');
  src: url('../../fonts/NationalWeb-Medium.eot#iefix') format('embedded-opentype'),
    url('../../fonts/NationalWeb-Medium.woff2') format('woff2'),
    url('../../fonts/NationalWeb-Medium.woff') format('woff'),
    url('../../fonts/NationalWeb-Medium.svg#NationalWeb-Medium') format('svg');
  font-display: block;
}

@font-face {
  font-family: 'NationalWeb-Regular';
  src: url('../../fonts/NationalWeb-Regular.eot');
  src: url('../../fonts/NationalWeb-Regular.eot#iefix') format('embedded-opentype'),
    url('../../fonts/NationalWeb-Regular.woff2') format('woff2'),
    url('../../fonts/NationalWeb-Regular.woff') format('woff'),
    url('../../fonts/NationalWeb-Regular.svg#NationalWeb-Regular') format('svg');
  font-display: block;
}

@font-face {
  font-family: 'NationalWeb-Thin';
  src: url('../../fonts/NationalWeb-Thin.eot');
  src: url('../../fonts/NationalWeb-Thin.eot#iefix') format('embedded-opentype'),
    url('../../fonts/NationalWeb-Thin.woff2') format('woff2'),
    url('../../fonts/NationalWeb-Thin.woff') format('woff'),
    url('../../fonts/NationalWeb-Thin.svg#NationalWeb-Thin') format('svg');
  font-display: block;
}

.capitalize {
  text-transform: capitalize;
}

.hide-button {
  display: none;
}

.hide-datepicker {
  display: none;
}

.subscription {
  width: 80%;
  max-width: 340px;
  margin: 15px 0;
}
